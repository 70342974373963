import { isPlatformServer } from '@angular/common';
import { effect, inject, PLATFORM_ID, untracked } from '@angular/core';
import {
  patchState,
  Prettify,
  signalStoreFeature,
  SignalStoreFeature,
  SignalStoreFeatureResult,
  StateSignals,
  withHooks,
  withState,
  WritableStateSource,
} from '@ngrx/signals';

interface PersistenceConfig<TData extends object, Input extends SignalStoreFeatureResult> {
  key: string;
  storage?: Storage;
  persist: (
    store: Prettify<StateSignals<Input['state']> & Input['computed'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
  ) => TData;
  restore: (
    store: Prettify<StateSignals<Input['state']> & Input['computed'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
    data: TData,
  ) => void;
}

function deserialize(data: string | null, storageType: string) {
  try {
    return data === null ? null : JSON.parse(data);
  } catch (e) {
    console.warn(`Error parsing ${storageType} storage data`, e);
    console.warn('Data:', data);
  }
}

function serialize(data: any) {
  return JSON.stringify(data);
}

export function withPersistence<TData extends object, Input extends SignalStoreFeatureResult>(
  config: PersistenceConfig<TData, Input>,
): SignalStoreFeature<
  Input,
  {
    state: {};
    computed: {};
    methods: {};
  }
> {
  const storageKey = `__StatePersistence__${config.key}`;
  return signalStoreFeature(
    withState(() => {
      if (isPlatformServer(inject(PLATFORM_ID))) return { [storageKey]: null };
      const storage: Storage = config.storage ?? sessionStorage;
      return {
        [storageKey]: storage.getItem(storageKey),
      };
    }),
    withHooks((store) => {
      if (isPlatformServer(inject(PLATFORM_ID))) return {};
      const storage: Storage = config.storage ?? sessionStorage;
      const storageType = storage === sessionStorage ? 'session' : 'local';
      return {
        onInit() {
          effect(
            () => {
              let _persistedData = (store as any)[storageKey]() as string | null;
              if (_persistedData === null) {
                _persistedData = untracked(() => serialize(config.persist(store as any)));
                patchState(store, { [storageKey]: _persistedData });
              } else {
                config.restore(store as any, deserialize(_persistedData, storageType));
              }
              storage.setItem(storageKey, _persistedData);
            },
            { allowSignalWrites: true },
          );
          effect(
            () => {
              const data = config.persist(store as any);
              patchState(store, { [storageKey]: serialize(data) });
            },
            { allowSignalWrites: true },
          );
        },
      };
    }),
  );
}
