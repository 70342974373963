import { convertNavItem } from '../base/convertNavItem';
import { evalItemProp } from '../util/evalItemProp';
import { ExternalLinkNavItem, ExternalLinkNavItemData } from './ExternalLinkNavItem';

export function convertExternalLinkNavItem(item: ExternalLinkNavItem): ExternalLinkNavItemData {
  return {
    ...convertNavItem(item),
    type: item.type,
    url: evalItemProp(item.url),
    target: evalItemProp(item.target),
  };
}
