import { isPlatformBrowser } from '@angular/common';
import { computed, effect, inject, PLATFORM_ID } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AppDimension } from 'common-ui';
import { withAppBreakpoints } from '../features/withAppBreakpoints';
import { AppPopoverStore } from './app-popover.store';
import { withPersistence } from '../features/withPersistence';

export interface AppMenuState {
  _opened: boolean;
}

export const AppMenuStore = signalStore(
  { providedIn: 'root' },
  withState<AppMenuState>(() => ({
    _opened: false,
  })),
  withAppBreakpoints(),
  withComputed(({ _opened: opened, breakpointWidth: breakpointWidth }) => {
    const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
    const isInline = computed(() => {
      if (!isBrowser) return false;
      const appWidthBp = breakpointWidth();
      if (appWidthBp > AppDimension.md) {
        return true;
      }
      return false;
    });
    const isOpen = computed(() => isInline() || opened());
    return {
      isInline,
      isOpen,
    };
  }),
  withMethods((store) => {
    const popoverStore = inject(AppPopoverStore);
    const open = () => {
      popoverStore.setActive('menu');
      patchState(store, { _opened: true });
    };
    const close = () => patchState(store, { _opened: false });
    const toggle = () => (store._opened() ? close() : open());

    return {
      open,
      close,
      toggle,
    };
  }),
  withHooks((store) => {
    let prevWidth: number | undefined = undefined;
    return {
      onInit: () => {
        effect(
          () => {
            const breakpointWidth = store.breakpointWidth();
            if (breakpointWidth > AppDimension.md) {
              patchState(store, { _opened: true });
            } else if (
              prevWidth != undefined &&
              prevWidth !== breakpointWidth &&
              prevWidth > AppDimension.md &&
              breakpointWidth <= AppDimension.md
            ) {
              patchState(store, { _opened: false });
            }
            prevWidth = breakpointWidth;
          },
          { allowSignalWrites: true },
        );
      },
    };
  }),
  withPersistence({
    key: 'idealsupply.ideallink.app-shell.menu',
    persist: (store) => ({
      _opened: store._opened(),
    }),
    restore: (store, data) => {
      patchState(store, { _opened: data._opened });
    },
  }),
);
