import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NavigationMenuBarComponent } from 'navigation-ui';
import { VersionInfoComponent } from '../version-info/version-info.component';
import { PrimaryNavigationBarComponentStore } from './primary-navigation-bar.store';

@Component({
  selector: 'ideal-primary-navigation-bar',
  standalone: true,
  imports: [NavigationMenuBarComponent, VersionInfoComponent, JsonPipe],
  templateUrl: './primary-navigation-bar.component.html',
  styleUrl: './primary-navigation-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PrimaryNavigationBarComponentStore],
})
export class PrimaryNavigationBarComponent {
  protected navStore = inject(PrimaryNavigationBarComponentStore);
}
