import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  booleanAttribute,
  input,
  viewChild,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ideal-window-toggle-button',
  standalone: true,
  imports: [MatIcon, MatIconButton],
  templateUrl: './window-toggle-button.component.html',
  styleUrls: ['../search-field-button-base/search-field-button-base.component.scss', './window-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindowToggleButtonComponent {
  private readonly _button = viewChild<MatIconButton, ElementRef<HTMLButtonElement>>(MatIconButton, { read: ElementRef });
  public readonly open = input(false, { transform: booleanAttribute });

  @HostBinding('class.active')
  protected get isActive() {
    return this.open();
  }

  @Output('focus')
  protected readonly focusEvent = new EventEmitter<FocusEvent>();

  @Output('blur')
  protected readonly blurEvent = new EventEmitter<FocusEvent>();

  public focus() {
    this._button()?.nativeElement.focus();
  }
}
