import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PrimaryNavigationBarComponent } from './primary-navigation-bar/primary-navigation-bar.component';
import { SecondaryNavigationMenuComponent } from './secondary-navigation-menu/secondary-navigation-menu.component';

@Component({
  selector: 'ideal-navigation-side-menu',
  standalone: true,
  imports: [PrimaryNavigationBarComponent, SecondaryNavigationMenuComponent],
  templateUrl: './navigation-side-menu.component.html',
  styleUrl: './navigation-side-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationSideMenuComponent {}
