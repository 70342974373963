import { Injector, runInInjectionContext } from '@angular/core';
import { NavItem, NavItemData } from '../../types/base/NavItem';
import { NavItemConverters } from '../../types/util/nav-item-converters';

export function addItem(injector: Injector, item: NavItem) {
  return runInInjectionContext(injector, () => {
    const converter = NavItemConverters[item.type as keyof typeof NavItemConverters] as unknown as
      | ((item: NavItem) => NavItemData)
      | undefined;
    if (!converter) {
      throw new Error(`No converter for item type '${item.type}'`);
    }
    return converter(item);
  });
}
