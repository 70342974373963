import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { SecondaryNavigationRenderTargetComponent } from './secondary-navigation-render-target.component';

@Directive({
  selector: '[renderInSecondaryNavigation]',
  standalone: true,
})
export class RenderInSecondaryNavigationDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(SecondaryNavigationRenderTargetComponent);
}
