import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Vendor, VendorsApi } from '@idealsupply/ngclient-webservices-vendors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VendorListResolver implements Resolve<Vendor[]> {
  constructor(private readonly api: VendorsApi) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Vendor[] | Observable<Vendor[]> | Promise<Vendor[]> {
    return this.api.searchVendorList().pipe(map((vr) => vr.data ?? []));
  }
}
