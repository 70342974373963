import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { APP_VERSION } from 'config-data';

@Component({
  selector: 'ideal-version-info',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './version-info.component.html',
  styleUrl: './version-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInfoComponent {
  protected readonly appVersion = inject(APP_VERSION);
}
