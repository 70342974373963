<div class="product-search">
  <div class="search-left" *renderInSecondaryNavigation>
    <mat-accordion multi>
      <ng-container *ngTemplateOutlet="punchoutIntegrationTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="categoryFilterTemplate"></ng-container>
    </mat-accordion>
  </div>
  <div class="results-left">
    <section>
      <mat-card class="mat-card-advanced-search">
        <ng-container *ngTemplateOutlet="searchFilterTemplate"></ng-container>
      </mat-card>

      <mat-form-field class="search-terms" *ngIf="searchTerms?.length">
        <mat-label>Search Terms</mat-label>
        <mat-chip-grid #chipGrid aria-label="Search Terms">
          @for (searchTerm of searchTerms; track $index) {
            <mat-chip-row [removable]="true" (removed)="removeSearchTerm(searchTerm)">
              <div>
                {{ searchTerm.label }}
              </div>
              <button matChipRemove [attr.aria-label]="'remove ' + searchTerm.label"><mat-icon>cancel</mat-icon></button>
            </mat-chip-row>
          }
        </mat-chip-grid>

        <!-- <mat-chip-set #chipList aria-label="Current search terms">
            <mat-chip *ngFor="let searchTerm of searchTerms" [removable]="true" (removed)="removeSearchTerm(searchTerm)">
              {{ searchTerm.label }}
              <button mat-icon-button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>



          </mat-chip-set>

          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"

        -->
        <input [matChipInputFor]="chipGrid" />
      </mat-form-field>
      <product-purchase-list
        [datasource]="productData"
        [hidden]="resultCount === 0"
        (loadingChange)="setLoading($event)"
        (titleClick)="handleTitleClick($event)"
      ></product-purchase-list>
      <mat-card *ngIf="resultCount === 0 && !isFilterEmpty && !isLoading">
        <mat-card-content> Your search did not yield any results. Please modify your search and try again </mat-card-content>
      </mat-card>
      <mat-card *ngIf="isFilterEmpty">
        <mat-card-title> Welcome to IdealLINK! </mat-card-title>
        <mat-card-content>
          You can use Part Numbers, Descriptions, Industry Terms, or our categories to get your search started. If you have your own part
          numbers loaded with us, you can also search using those.
        </mat-card-content>
      </mat-card>
    </section>
  </div>
</div>
<div class="search-progress" *ngIf="isLoading">
  <div>
    <h3>Searching</h3>
  </div>
</div>

<ng-template #searchFilterTemplate>
  <mat-expansion-panel [expanded]="false" class="advanced-search-pod pod">
    <mat-expansion-panel-header>
      <mat-panel-title> Advanced Search </mat-panel-title>
    </mat-expansion-panel-header>
    <form class="search" [formGroup]="searchForm" (ngSubmit)="search()">
      <mat-slide-toggle formControlName="productAvailable">Available Items Only</mat-slide-toggle>
      <mat-form-field class="half">
        <mat-label>Line Code</mat-label>
        <input matInput formControlName="lineCode" />
      </mat-form-field>
      <mat-form-field class="half">
        <mat-label>Part Number</mat-label>
        <input matInput formControlName="partNumber" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput formControlName="partDescription" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vendor</mat-label>
        <mat-select formControlName="vendorNum">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let vendor of vendors" [value]="vendor.id">
            {{ vendor.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <cart-saved-list-select *ngIf="isAuthenticated$ | async" formControlName="partList"></cart-saved-list-select>

      <button mat-stroked-button type="submit" color="accent" class="half" [disabled]="isLoading">Search</button>
      <button mat-stroked-button type="button" class="half" (click)="clearSearch()" [disabled]="isLoading">Clear</button>
    </form>
  </mat-expansion-panel>
</ng-template>

<ng-template #categoryFilterTemplate>
  <mat-expansion-panel hideToggle [expanded]="true" class="category-filter-pod pod">
    <mat-expansion-panel-header>
      <mat-panel-title> Product Categories </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-tree *ngIf="categories$ | async" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-nested-tree-node
        *matTreeNodeDef="let node"
        class="node"
        [class.has-children]="hasChild(node)"
        [class.expanded]="isExpanded(node)"
        [ngClass]="getSelectedState(node)"
      >
        <ng-container *ngTemplateOutlet="CategoryNodeItem; context: { $implicit: node }"></ng-container>

        <div class="children" *ngIf="isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </mat-expansion-panel>
</ng-template>

<ng-template #CategoryNodeItem let-node>
  <div class="item" (click)="clickedCategoryNode(node)">
    <div class="icon">
      <ng-container *ngIf="hasChild(node)">
        <mat-icon class="mat-icon-rtl-mirror"> arrow_drop_down </mat-icon>
      </ng-container>
    </div>
    <div class="image"><img *ngIf="node.imageUrl" [src]="node.imageUrl" /></div>
    <div class="label">{{ node.name }}</div>
  </div>
</ng-template>

<ng-template #punchoutIntegrationTemplate>
  <ng-container *ngIf="punchOutIntegrations$ | async as punchOutIntegrations">
    <mat-expansion-panel [expanded]="true" class="punchout-integration-pod pod" *ngIf="punchOutIntegrations.length">
      <mat-expansion-panel-header>
        <mat-panel-title> Vendor Product Selectors </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let poi of punchOutIntegrations" (click)="openPunchOut(poi)" [disabled]="!(hasCartId$ | async)">{{
          poi.label
        }}</a>
      </mat-nav-list>
    </mat-expansion-panel>
  </ng-container>
</ng-template>
