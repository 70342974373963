import { convertNavItem } from '../base/convertNavItem';
import { evalItemProp } from '../util/evalItemProp';
import { RouteNavItem, RouteNavItemData } from './RouteNavItem';

export function convertRouteNavItem(item: RouteNavItem): RouteNavItemData {
  return {
    ...convertNavItem(item),
    type: item.type,
    command: evalItemProp(item.command),
    args: evalItemProp(item.args),
    linkOptions: evalItemProp(item.linkOptions),
  };
}
