import { inject, Type } from '@angular/core';
import { signalStore, withState } from '@ngrx/signals';
import { FEATURE_IMPORT_COMPONENT } from 'common-util';

export interface FeatureImportComponentsState {
  components: Type<any>[];
}

export const FeatureImportComponentsStore = signalStore(
  { providedIn: 'root' },
  withState<FeatureImportComponentsState>(() => ({
    components: inject(FEATURE_IMPORT_COMPONENT, { optional: true }) ?? [],
  })),
);
