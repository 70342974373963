import { computed, inject } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { AppPopoverStore } from '../stores/app-popover.store';
import { withPersistence } from './withPersistence';

export interface ApplicationHeaderDrawerSlice {
  openedDrawerId: string | null;
}

export const withApplicationHeaderDrawers = () =>
  signalStoreFeature(
    withState<ApplicationHeaderDrawerSlice>({
      openedDrawerId: null,
    }),
    withComputed((state) => ({
      isDrawerOpen: computed(() => state.openedDrawerId() !== null),
    })),
    withMethods((state) => {
      const popoverStore = inject(AppPopoverStore);

      const open = (drawer: string) => {
        popoverStore.setActive('header');
        patchState(state, { openedDrawerId: drawer });
      };

      const close = (drawer?: string) => {
        if (!drawer || state.openedDrawerId() === drawer) {
          // popoverStore.setActive(null);
          patchState(state, { openedDrawerId: null });
        }
      };
      const toggle = (drawer: string) => (state.openedDrawerId() === drawer ? close(drawer) : open(drawer));

      return {
        openDrawer: open,
        toggleDrawer: toggle,
        closeDrawer: close,
      };
    }),
    withPersistence({
      key: 'idealsupply.ideallink.app-shell.header-drawers',
      persist: (store) => ({
        openedDrawerId: store.openedDrawerId(),
      }),
      restore: (store, data) => {
        data.openedDrawerId === null ? store.closeDrawer() : store.openDrawer(data.openedDrawerId);
      },
    }),
  );
