import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, booleanAttribute, computed, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'a[ideal-navigation-list-item], button[ideal-navigation-list-item]',
  standalone: true,
  imports: [MatRippleModule, MatIconModule, JsonPipe],
  templateUrl: './navigation-list-item.component.html',
  styleUrls: ['../navigation-theme-vars.scss', './navigation-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.ideal-navigation-list-item]': 'true',
    '[class.active]': 'isActive()',
    '[attr.disabled]': 'disabled() ? "" : undefined',
  },
})
export class NavigationListItemComponent {
  private readonly _rla = inject(RouterLinkActive, { self: true, optional: true });
  private readonly _rlaIsActive = this._rla ? toSignal(this._rla.isActiveChange, { initialValue: false }) : signal(false);

  public readonly open = input(false, { transform: booleanAttribute });
  public readonly menu = input(false, { transform: booleanAttribute });
  public readonly disabled = input(false, { transform: booleanAttribute });
  public readonly active = input(false, { transform: booleanAttribute });
  public readonly label = input('');
  public readonly icon = input<string>();

  protected readonly isActive = computed(() => this._rlaIsActive() || this.active());
}
