import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { APP_CONFIG, APP_VERSION } from 'config-data';
import { EMPTY, Observable, Subject, filter, interval, merge, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private readonly _version = inject(APP_VERSION);
  private readonly _appConfig = inject(APP_CONFIG);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private readonly _versionPush$$ = new Subject<string>();
  private readonly _versionCheckTimer$ = this.isBrowser
    ? interval(this._appConfig.appVersionCheckInterval ?? 60000).pipe(
        switchMap(async () => {
          try {
            const r = await fetch('/version.json', { method: 'GET' });
            const v = (await r.json()) as { version: string };
            return v.version;
          } catch (e) {
            console.error('Failed to fetch version.json');
            console.error(e);
            return this._version;
          }
        }),
      )
    : EMPTY;

  public readonly versionCheck$ = this.isBrowser
    ? merge(this._versionPush$$, this._versionCheckTimer$).pipe(filter((version) => version !== this._version))
    : EMPTY;

  public get version(): string {
    return this._version;
  }

  public pushVersion(version: string): void {
    this._versionPush$$.next(version);
  }

  public get versionChange$(): Observable<string> {
    return this.versionCheck$;
  }
}
