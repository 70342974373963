import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HorizontalLogoComponent } from 'logos-ui';

@Component({
  selector: 'ideal-app-header-logo',
  standalone: true,
  imports: [HorizontalLogoComponent, RouterLink],
  templateUrl: './app-header-logo.component.html',
  styleUrl: './app-header-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderLogoComponent {}
