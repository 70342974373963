import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { getActiveTheme, setTheme } from '@cpangular/ng-material-theme';
import { IdServerConnectionError, bootstrapAuthentication } from 'authentication-data';
import { AppEnvironment, createModifyApiInterceptor } from 'config-data';

export async function initializeApplication(env: AppEnvironment, cfg: ApplicationConfig) {
  try {
    const apiRewrites = env.apiRewrites ?? {};

    for (const [from, to] of Object.entries(apiRewrites)) {
      if (from !== to) {
        cfg.providers.push({
          provide: HTTP_INTERCEPTORS,
          useClass: createModifyApiInterceptor(from, to),
          multi: true,
        });
      }
    }

    cfg.providers.push(...env.providers);

    if (getActiveTheme() !== env.defaultTheme) {
      setTheme(env.defaultTheme ?? undefined);
    }

    await bootstrapAuthentication(env.auth, cfg);
  } catch (err) {
    if (err instanceof IdServerConnectionError) {
      document.body.classList.add('fatal-error');
      document.body.classList.add('id-server-connection-error');
    }
    throw err;
  }
}
