import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, viewChild } from '@angular/core';
import { LottieComponent } from 'common-ui';
import { AppHeaderButtonComponent } from '../app-header-button/app-header-button.component';

@Component({
  selector: 'ideal-app-header-menu-button',
  standalone: true,
  imports: [AppHeaderButtonComponent, LottieComponent, JsonPipe],
  templateUrl: './app-header-menu-button.component.html',
  styleUrl: './app-header-menu-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMenuButtonComponent {
  private _initialized = false;
  private readonly _lottie = viewChild(LottieComponent);

  public readonly open = input(false);
  public readonly color = input<string>('');

  constructor() {
    effect(() => {
      const open = this.open();
      const lottie = this._lottie();
      if (this._initialized && lottie) {
        if (open) {
          lottie.setDirection(1);
          lottie.goToAndPlay(0, true);
        } else {
          lottie.setDirection(-1);
          lottie.goToAndPlay(lottie.totalFrames, true);
        }
      }
    });
  }

  protected lottieLoaded() {
    const lottie = this._lottie();
    if (lottie) {
      if (this.open()) {
        lottie.goToAndStop(lottie.totalFrames, true);
      } else {
        lottie.goToAndStop(0, true);
      }
    }
    this._initialized = true;
  }
}
