import { Injectable, computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter, skip } from 'rxjs';
import { AppHeaderStore } from '../state/stores/app-header.store';
import { AppMenuStore } from '../state/stores/app-menu.store';
import { AppPopoverStore } from '../state/stores/app-popover.store';
import { AppSearchbarStore } from '../state/stores/app-searchbar.store';
import { AppSideDrawersStore } from '../state/stores/app-side-drawers.store';
import { FeatureImportComponentsStore } from '../state/stores/feature-import-components.store';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private readonly _router = inject(Router);

  private readonly _popoverStore = inject(AppPopoverStore);
  public readonly featureImportComponents = inject(FeatureImportComponentsStore);
  public readonly searchbar = inject(AppSearchbarStore);
  public readonly header = inject(AppHeaderStore);
  public readonly menu = inject(AppMenuStore);
  public readonly sideDrawers = inject(AppSideDrawersStore);

  public readonly breakpointWidth = this.menu.breakpointWidth;
  public readonly breakpointHeight = this.menu.breakpointHeight;
  public readonly breakpointOrientation = this.menu.breakpointOrientation;

  public readonly isPopoversActive = computed(
    () => this.header.isDrawerOpen() || (!this.menu.isInline() && this.menu.isOpen()) || this.sideDrawers.isOpen(),
  );

  public closePopovers() {
    this.header.closeDrawer();
    this.sideDrawers.close();
    if (!this.menu.isInline()) {
      this.menu.close();
    }
  }

  public constructor() {
    effect(this.closeOtherDrawers.bind(this), { allowSignalWrites: true });
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd || e instanceof NavigationError),
        skip(1),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.closePopovers());
  }

  private closeOtherDrawers() {
    const activeId = this._popoverStore.activeId();
    if (activeId === 'menu') {
      this.header.closeDrawer();
      this.sideDrawers.close();
    } else if (activeId === 'header') {
      this.menu.close();
      this.sideDrawers.close();
    } else if (activeId === 'side') {
      this.menu.close();
      this.header.closeDrawer();
    }
    this._popoverStore.setActive(null);
  }
}
