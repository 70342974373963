import { ChangeDetectionStrategy, Component, booleanAttribute, computed, input, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ElementResizeDirective } from 'common-util';

@Component({
  selector: 'ideal-app-header-button',
  standalone: true,
  imports: [MatButton, ElementResizeDirective],
  templateUrl: './app-header-button.component.html',
  styleUrl: './app-header-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderButtonComponent {
  private static _clipPathId = 0;
  protected size = signal({ width: 0, height: 0 });

  protected readonly clipPathName = 'app-shell-header-button-clip-path-' + AppHeaderButtonComponent._clipPathId++;

  protected clipPath = computed(() => {
    const size = this.size();
    return `M 0,0 H ${size.width + size.height * 0.6} L ${size.width},${size.height} H 0 Z`;
  });

  protected clipPathReverse = computed(() => {
    const size = this.size();
    return `M 0,${size.height} L ${size.height * 0.6},0 H ${size.width + size.height * 0.6} V ${size.height} Z`;
  });

  public readonly disabled = input(false, { transform: booleanAttribute });
}
