<mat-card cdkTrapFocus>
  <button focus="start" (focus)="onFocusFirst($event)"></button>
  @if (state() === State.Refreshing) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  @if (state() !== State.Idle) {
    <div cdkScrollable class="scroll-container">
      <mat-card-content [class]="state()">
        @switch (state()) {
          <!-- @case (State.Idle) {
          <label i18n="@@AppQuickSearch_SearchResults_Hint">Type a search query to get started.</label>
        } -->
          @case (State.Error) {
            <h2 i18n="@@Error">Error</h2>
            <pre>{{ error() | json }}</pre>
          }
          <!-- @case (State.Loading) {
            <label i18n="@@AppQuickSearch_SearchingInitialMessage">Let's see what we can find</label>
            <mat-progress-spinner mode="indeterminate" diameter="200"></mat-progress-spinner>
          } -->
          <!-- @case (State.Empty) {
            <label i18n="@@AppQuickSearch_NoResultsMessage">Nothing found</label>
          } -->
          @default {
            <ideal-render-target-app-quick-search-results></ideal-render-target-app-quick-search-results>
          }
        }
      </mat-card-content>
    </div>
  }
  <button focus="end" (focus)="onFocusLast($event)"></button>
</mat-card>
