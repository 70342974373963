import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ProductComponent } from '../../product.component';

@Component({
  selector: 'feature-default',
  template: '',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DefaultComponent {
  constructor(
    private readonly productComponent: ProductComponent,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.productComponent.tabs$.pipe(first()).subscribe((tabs) => {
      if (tabs.length) {
        this.router.navigate(tabs[0].cmd, {
          relativeTo: this.route,
          replaceUrl: true,
        });
      }
    });
  }
}
