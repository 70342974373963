import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal } from '@angular/core';
import { ApplicationService } from 'app-shell-data';
import { DrawerComponent, DrawerGroupComponent, NamedRenderInSource, RenderInTargetComponentBase } from 'common-ui';

@Component({
  selector: 'ideal-app-side-drawers',
  standalone: true,
  imports: [DrawerGroupComponent, DrawerComponent, NgTemplateOutlet],
  templateUrl: './app-side-drawers.component.html',
  styleUrl: './app-side-drawers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSideDrawersComponent extends RenderInTargetComponentBase {
  private readonly _store = inject(ApplicationService);

  protected readonly drawers = this.renderItems as Signal<NamedRenderInSource<undefined>[]>;

  protected readonly selectedDrawerIndex = computed(() => {
    const drawers = this.drawers();
    const drawerId = this._store.sideDrawers.openedDrawerId();
    return drawerId ? drawers.findIndex((drawer) => drawer.renderInSourceName === drawerId) : -1;
  });

  public override get context() {
    return undefined;
  }
}
