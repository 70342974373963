<button mat-button [disabled]="disabled()" (sizeChange)="size.set($event)" [style.clip-path]="'url(#' + clipPathName + ')'">
  <div class="icon"><ng-content select="[icon]"></ng-content></div>
  <div class="label"><ng-content></ng-content></div>
</button>
<svg class="wedge" version="1.1">
  <clipPath [attr.id]="clipPathName">
    <path [attr.d]="clipPath()" />
    <path class="reverse" [attr.d]="clipPathReverse()" />
  </clipPath>
</svg>
