<!-- <ngwc-page #page pageTitle="Promotion" [stickyHeader]="true"> -->
<div class="layout" *ngIf="product$ | async as product">
  <div class="content">
    <header>
      <product-image-viewer [product]="product"></product-image-viewer>
      <div class="info">
        <product-list-item-detail [item]="product"></product-list-item-detail>
        <product-list-item-price [item]="product"></product-list-item-price>
        <cart-item-fee-calc price [item]="product"></cart-item-fee-calc>
      </div>
    </header>
    <ng-container *ngIf="tabs$ | async as tabs">
      <nav-tabs *ngIf="tabs.length" [tabs]="tabs"></nav-tabs>
    </ng-container>
    <section class="subsections">
      <router-outlet></router-outlet>
    </section>
  </div>

  <mat-card hideToggle class="cart">
    <cart-add-remove-button [item]="product"></cart-add-remove-button>
  </mat-card>

  <mat-card hideToggle class="stock">
    <product-list-item-stock [item]="product"></product-list-item-stock>
  </mat-card>

  <mat-card hideToggle class="pdf" *ngIf="product?.specsheet?.url">
    <a mat-stroked-button [href]="product?.specsheet?.url" target="_blank">Download {{ product?.specsheet?.title }}</a>
  </mat-card>

  <mat-card hideToggle class="cross-references" *ngIf="references$ | async as references">
    <mat-card-title>Cross References</mat-card-title>
    <mat-card-content>
      <div *ngFor="let ref of references">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>{{ ref.customerNumber }} Cross Reference</mat-label>
          <input
            #crossRef
            matInput
            placeholder="Enter Cross Reference"
            [value]="ref.crossReference ?? ''"
            (change)="setCrossReference(product, ref.customerNumber!, crossRef.value)"
          />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<!-- </ngwc-page> -->
