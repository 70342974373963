import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { SecondaryNavigationFrameRenderTargetComponent } from './secondary-navigation-frame-render-target.component';

@Directive({
  selector: '[renderInSecondaryNavigationFrame]',
  standalone: true,
})
export class RenderInSecondaryNavigationFrameDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(SecondaryNavigationFrameRenderTargetComponent);
}
