import { CdkScrollable, CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, effect, inject, viewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApplicationService } from 'app-shell-data';
import { map, Subscription, tap } from 'rxjs';

@Component({
  selector: 'ideal-app-body',
  standalone: true,
  imports: [RouterModule, CdkScrollableModule, ScrollingModule],
  templateUrl: './app-body.component.html',
  styleUrl: './app-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBodyComponent {
  private readonly _store = inject(ApplicationService);
  private readonly _scrollContainer = viewChild.required(CdkScrollable);

  private _enableScrollListener() {
    let scrollSub: Subscription;
    effect((onCleanup) => {
      const scrollContainer = this._scrollContainer();
      scrollSub?.unsubscribe();
      scrollSub = scrollContainer
        .elementScrolled()
        .pipe(
          map((event) => (event.target as HTMLElement).scrollTop),
          tap((scrollTop) => this._store.header.updateScrollPosition(scrollTop)),
        )
        .subscribe();
      onCleanup(() => {
        scrollSub?.unsubscribe();
      });
    });
  }

  constructor() {
    this._enableScrollListener();
  }
}
