import { ChangeDetectionStrategy, Component, booleanAttribute, computed, inject, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'a[ideal-navigation-menu-bar-button], button[ideal-navigation-menu-bar-button]',
  standalone: true,
  imports: [MatRipple, MatIcon],
  templateUrl: './navigation-menu-bar-button.component.html',
  styleUrls: ['../navigation-theme-vars.scss', './navigation-menu-bar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.ideal-navigation-menu-bar-button]': 'true',
    '[class.active]': 'isActive()',
    '[class.open]': 'open()',
    '[class.menu]': 'menu()',
    '[attr.disabled]': 'disabled() ? "" : undefined',
  },
})
export class NavigationMenuBarButtonComponent {
  private readonly _rla = inject(RouterLinkActive, { self: true, optional: true });
  private readonly _rlaIsActive = this._rla ? toSignal(this._rla.isActiveChange, { initialValue: false }) : signal(false);

  public readonly open = input(false, { transform: booleanAttribute });
  public readonly menu = input(false, { transform: booleanAttribute });
  public readonly disabled = input(false, { transform: booleanAttribute });
  public readonly active = input(false, { transform: booleanAttribute });
  public readonly label = input('');
  public readonly icon = input<string>();

  protected readonly isActive = computed(() => this._rlaIsActive() || this.active());
}
