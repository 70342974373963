import { evalItemProp } from '../util/evalItemProp';
import { convertRouteNavItem } from '../route/convertRouteNavItem';
import { RouteLinkNavItem, RouteLinkNavItemData } from './RouteLinkNavItem';

export function convertRouteLinkNavItem(item: RouteLinkNavItem): RouteLinkNavItemData {
  return {
    ...convertRouteNavItem(item),
    type: item.type,
    command: evalItemProp(item.command),
    args: evalItemProp(item.args),
    target: evalItemProp(item.target),
  };
}
