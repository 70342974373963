import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { AppActionsSecondaryRenderTargetComponent } from './app-actions-secondary-render-target.component';

@Directive({
  selector: '[renderInAppActionsSecondary]',
  standalone: true,
})
export class RenderInAppActionsSecondaryDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(AppActionsSecondaryRenderTargetComponent);
}
