import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ApplicationService } from 'app-shell-data';
import { DrawerComponent } from 'common-ui';
import { AppPrimaryMenuRenderTargetComponent } from '../../render-targets/app-primary-menu/app-primary-menu-render-target.component';

@Component({
  selector: 'ideal-app-menu',
  standalone: true,
  imports: [DrawerComponent, AppPrimaryMenuRenderTargetComponent],
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent {
  private readonly _store = inject(ApplicationService);

  protected menuIsOpen = this._store.menu.isOpen;
}
