import { Directive, input, signal } from '@angular/core';
import { NamedRenderInSource, RenderInSourceDirectiveBase } from 'common-ui';
import { AppHeaderDrawersComponent } from './app-header-drawers.component';

@Directive({
  selector: '[appHeaderDrawer]',
  standalone: true,
})
export class AppHeaderDrawerDirective extends RenderInSourceDirectiveBase implements NamedRenderInSource {
  protected readonly renderIn = signal(AppHeaderDrawersComponent);

  public readonly drawer = input.required<string>({ alias: 'appHeaderDrawer' });
  public get renderInSourceName() {
    return this.drawer();
  }
}
