import { animate, style, transition, trigger } from '@angular/animations';
import { JsonPipe, NgFor, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, PLATFORM_ID, computed, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonNavItemData,
  ExternalLinkNavItemData,
  MightHaveChildren,
  NavItemDataTypes,
  NavItemType,
  RouteButtonNavItemData,
  RouteLinkNavItemData,
} from 'navigation-data';
import { NavigationListItemComponent } from '../navigation-list-item/navigation-list-item.component';

@Component({
  selector: 'ideal-navigation-list',
  standalone: true,
  imports: [NavigationListItemComponent, RouterModule, NgFor, JsonPipe],
  templateUrl: './navigation-list.component.html',
  styleUrls: ['../navigation-theme-vars.scss', './navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))]),
      transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
    ]),
  ],
})
export class NavigationListComponent {
  protected readonly NavItemType = NavItemType;
  protected readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  items = input.required<(NavItemDataTypes & MightHaveChildren)[]>();

  protected readonly groups = computed(() => {
    return Object.entries(Object.groupBy(this.items(), (i) => i.heading() ?? ''))
      .map(([heading, items]) => ({ heading, items: items ?? [] }))
      .sort((a, b) => {
        if (a.heading === '') return -1;
        if (b.heading === '') return 1;
        const aPriority = a.items[0].priority() ?? 0;
        const bPriority = b.items[0].priority() ?? 0;
        if (aPriority < bPriority) return 1;
        if (aPriority > bPriority) return -1;
        return a.heading.localeCompare(b.heading);
      });
  });

  protected cast(item: NavItemDataTypes, type: NavItemType.Button): ButtonNavItemData & MightHaveChildren;
  protected cast(item: NavItemDataTypes, type: NavItemType.ExternalLink): ExternalLinkNavItemData & MightHaveChildren;
  protected cast(item: NavItemDataTypes, type: NavItemType.RouteButton): RouteButtonNavItemData & MightHaveChildren;
  protected cast(item: NavItemDataTypes, type: NavItemType.RouteLink): RouteLinkNavItemData & MightHaveChildren;
  protected cast(item: NavItemDataTypes, _: NavItemType): any {
    return item;
  }

  animStart(evt: any) {
    // console.log('animStart', evt);
  }
  animDone(evt: any) {
    // console.log('animDone', evt);
  }
}
