import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { AppPopoverStore } from './app-popover.store';
import { withPersistence } from '../features/withPersistence';

export interface AppSideDrawersState {
  openedDrawerId: string | null;
}

export const AppSideDrawersStore = signalStore(
  { providedIn: 'root' },
  withState<AppSideDrawersState>({
    openedDrawerId: null,
  }),
  withComputed((store) => ({
    isOpen: computed(() => store.openedDrawerId() !== null),
  })),
  withMethods((store) => {
    const popoverStore = inject(AppPopoverStore);
    const open = (drawer: string) => {
      popoverStore.setActive('side');
      patchState(store, { openedDrawerId: drawer });
    };

    const close = (drawer?: string) => {
      if (!drawer || store.openedDrawerId() === drawer) {
        patchState(store, { openedDrawerId: null });
      }
    };
    const toggle = (drawer: string) => (store.openedDrawerId() === drawer ? close(drawer) : open(drawer));

    return {
      open,
      toggle,
      close,
    };
  }),
  withPersistence({
    key: 'idealsupply.ideallink.app-shell.side-drawers',
    persist: (store) => ({
      openedDrawerId: store.openedDrawerId(),
    }),
    restore: (store, data) => {
      data.openedDrawerId === null ? store.close() : store.open(data.openedDrawerId);
    },
  }),
);
