import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CategoryNode, InventoryApi } from '@idealsupply/ngclient-webservice-inventory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoriesResolver implements Resolve<CategoryNode[]> {
  constructor(private readonly api: InventoryApi) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CategoryNode[]> {
    return this.api.searchCategories();
  }
}
