import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IdealLinkLogoComponent, VerticalLogoComponent } from 'logos-ui';

@Component({
  selector: 'ideal-splash-screen',
  standalone: true,
  imports: [IdealLinkLogoComponent, VerticalLogoComponent],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent {}
