<ideal-app-header (heightChange)="headerHeight.set($event)" />
<ideal-app-header-drawers />
<ideal-app-menu />
<div popover-backdrop [class.show]="showPopoverBackdrop()" (click)="onPopoverBackdropClick()"></div>
<div content-container>
  <ideal-app-body [class.fade]="showPopoverBackdrop()" />
  <ideal-legacy-loading-screen />
</div>
<ideal-app-side-drawers />
<ideal-splash-screen />

@if (hasSearchbar()) {
  <ideal-app-search-field *renderInAppToolbar />
}

<div class="feature-imports">
  @for (comp of featureImportComponents(); track $index) {
    <ng-container *ngComponentOutlet="comp" />
  }
</div>
