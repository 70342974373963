import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  booleanAttribute,
  effect,
  input,
  viewChild,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { LottieComponent } from 'common-ui';

@Component({
  selector: 'ideal-search-clear-button',
  standalone: true,
  imports: [LottieComponent, MatIconButton],
  templateUrl: './search-clear-button.component.html',
  styleUrls: ['../search-field-button-base/search-field-button-base.component.scss', './search-clear-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchClearButtonComponent {
  private readonly _button = viewChild<MatIconButton, ElementRef<HTMLButtonElement>>(MatIconButton, { read: ElementRef });

  private _initialized = false;
  private readonly _lottie = viewChild(LottieComponent);

  public readonly reset = input(false, { transform: booleanAttribute });
  public readonly disabled = input(false, { transform: booleanAttribute });

  @HostBinding('class.active')
  protected get isActive() {
    return this.reset();
  }

  constructor() {
    effect(() => {
      const open = this.reset();
      const lottie = this._lottie();
      if (this._initialized && lottie) {
        if (open) {
          lottie.setDirection(1);
          lottie.goToAndPlay(0, true);
        } else {
          lottie.setDirection(-1);
          lottie.goToAndPlay(lottie.totalFrames, true);
        }
      }
    });
  }

  protected lottieLoaded() {
    const lottie = this._lottie();
    if (lottie) {
      if (this.reset()) {
        lottie.goToAndStop(lottie.totalFrames, true);
      } else {
        lottie.goToAndStop(0, true);
      }
    }
    this._initialized = true;
  }

  @Output('focus')
  protected readonly focusEvent = new EventEmitter<FocusEvent>();

  @Output('blur')
  protected readonly blurEvent = new EventEmitter<FocusEvent>();

  public focus() {
    this._button()?.nativeElement.focus();
  }
}
