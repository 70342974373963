import { computed, inject } from '@angular/core';
import { ActivationStart, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { patchState, signalStore, withComputed, withHooks, withState } from '@ngrx/signals';
import { RenderInService } from 'common-ui';
import { MightHaveChildren, NavItemDataTypes, NavigationStore } from 'navigation-data';
import { Subscription } from 'rxjs';
import { SecondaryNavigationFrameRenderTargetComponent } from './renter-targets/secondary-navigation-frame-render-target.component';
import { SecondaryNavigationRenderTargetComponent } from './renter-targets/secondary-navigation-render-target.component';

interface SecondaryNavigationMenuComponentSate {
  navigationId: string | null;
}

export const SecondaryNavigationMenuComponentStore = signalStore(
  withState<SecondaryNavigationMenuComponentSate>({
    navigationId: null,
  }),
  withComputed((state) => {
    const navStore = inject(NavigationStore);
    const renderInService = inject(RenderInService);

    const hasRenderSources = computed(
      () =>
        renderInService.hasSources(SecondaryNavigationFrameRenderTargetComponent) ||
        renderInService.hasSources(SecondaryNavigationRenderTargetComponent),
    );

    const navChildren = computed(() => {
      const navId = state.navigationId();
      const items = (navId ? navStore.getChildrenOf(navId) : []) as (NavItemDataTypes & MightHaveChildren)[];
      items.forEach((item) => (item.hasChildren = computed(() => navStore.getChildrenOf(item.id).length > 0)));
      return items;
    });
    const hasNavChildren = computed(() => navChildren().length > 0);

    const menuOpen = computed(() => {
      return hasNavChildren() || hasRenderSources();
    });

    return {
      menuOpen,
      navChildren,
      hasNavChildren,
    };
  }),
  withHooks((state) => {
    const router = inject(Router);
    let subscription: Subscription | undefined = undefined;
    let navId: string = '';
    let lastNavId: string = '';
    return {
      onInit: () => {
        subscription = router.events.subscribe((event) => {
          if (event instanceof ActivationStart) {
            navId = event.snapshot.data?.['navigationId'] || '';
          } else if (event instanceof NavigationEnd) {
            lastNavId = navId;
            patchState(state, { navigationId: navId || null });
          } else if (event instanceof NavigationError || event instanceof NavigationCancel) {
            navId = lastNavId;
            patchState(state, { navigationId: navId || null });
          }
        });
      },
      onDestroy: () => {
        subscription?.unsubscribe();
      },
    };
  }),
);
