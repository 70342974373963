import { inject } from '@angular/core';
import { signalStore, withState } from '@ngrx/signals';
import { SearchService } from '../../services';

export interface AppSearchbarState {
  hasSearchbar: boolean;
}

export const AppSearchbarStore = signalStore(
  { providedIn: 'root' },
  withState<AppSearchbarState>(() => ({ hasSearchbar: !!inject(SearchService, { optional: true }) })),
);
