import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const fadeOutSplashScreenAnimation = trigger('fadeOutSplashScreen', [
  transition(':enter', [
    group([
      query('ideal-app-header', [style({ opacity: 0, zIndex: 101 }), animate('0.25s ease-in', style({ opacity: 1 }))]),
      query('ideal-splash-screen', [style({ opacity: 1 }), animate('1.25s ease-in', style({ opacity: 0 }))]),
      query('[content-container]', [style({ opacity: 0 }), animate('1.25s ease-in', style({ opacity: 1 }))]),
    ]),
  ]),
]);
