import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { InventoryApi } from '@idealsupply/ngclient-webservice-inventory';
import { ProductListDataSource } from 'lib-products';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductListResolver implements Resolve<ProductListDataSource> {
  constructor(private readonly api: InventoryApi) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): ProductListDataSource | Observable<ProductListDataSource> | Promise<ProductListDataSource> {
    return new ProductListDataSource(this.api, route.queryParams['skip'], route.queryParams['limit']).initialize({
      category: route.queryParams['cat'],
      lineCode: route.queryParams['lc'],
      partDescription: route.queryParams['desc'],
      partNumber: route.queryParams['pn'],
      partList: route.queryParams['pl'],
      productAvailable: route.queryParams['ao'],
      subCategory2: route.queryParams['cat3'],
      subCategory: route.queryParams['cat2'],
      vendorNum: route.queryParams['ven'],
    });
  }
}
