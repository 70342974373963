import { signalStore, withState } from '@ngrx/signals';
import { withAppBreakpoints } from '../features/withAppBreakpoints';
import { withHideHeaderOnScroll } from '../features/withHideHeaderOnScroll';
import { withApplicationHeaderDrawers } from '../features/withApplicationHeaderDrawers';

export const AppHeaderStore = signalStore(
  { providedIn: 'root' },
  withAppBreakpoints(),
  withHideHeaderOnScroll((store) => store.breakpointWidth),
  withApplicationHeaderDrawers(),
);
