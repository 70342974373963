import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationSideMenuComponent } from '../../navigation-side-menu/navigation-side-menu.component';
import { RenderInAppPrimaryMenuDirective } from 'app-shell-ui';

@Component({
  selector: 'ideal-navigation-side-menu-importer',
  standalone: true,
  imports: [NavigationSideMenuComponent, RenderInAppPrimaryMenuDirective],
  template: `<ideal-navigation-side-menu *renderInAppPrimaryMenu></ideal-navigation-side-menu>`,
  styles: `
    :host {
      display: none;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationSideMenuImporterComponent {}
