@for (item of items(); track item.id) {
  @switch (item.type) {
    @case (NavItemType.Button) {
      @if (cast(item, NavItemType.Button); as item) {
        <button
          ideal-navigation-menu-bar-button
          [label]="item.label()"
          [icon]="item.icon()"
          (click)="item.onClick($event)"
          [disabled]="item.disabled()"
          [active]="item.active()"
        ></button>
      }
    }
    @case (NavItemType.ExternalLink) {
      @if (cast(item, NavItemType.ExternalLink); as item) {
        <a
          ideal-navigation-menu-bar-button
          [label]="item.label()"
          [icon]="item.icon()"
          [href]="item.url()"
          [target]="item.target() ?? '_blank'"
          [disabled]="item.disabled()"
        ></a>
      }
    }
    @case (NavItemType.RouteButton) {
      @if (cast(item, NavItemType.RouteButton); as item) {
        <button
          ideal-navigation-menu-bar-button
          [label]="item.label()"
          [icon]="item.icon()"
          [routerLink]="item.command()"
          [queryParams]="item.args()?.queryParams"
          [fragment]="item.args()?.fragment"
          [queryParamsHandling]="item.args()?.queryParamsHandling"
          [state]="item.args()?.state"
          [info]="item.args()?.info"
          [relativeTo]="item.args()?.relativeTo"
          [preserveFragment]="item.args()?.preserveFragment"
          [skipLocationChange]="item.args()?.skipLocationChange"
          [replaceUrl]="item.args()?.replaceUrl"
          [disabled]="item.disabled()"
          routerLinkActive
        ></button>
      }
    }
    @case (NavItemType.RouteLink) {
      @if (cast(item, NavItemType.RouteLink); as item) {
        <a
          ideal-navigation-menu-bar-button
          [label]="item.label()"
          [icon]="item.icon()"
          [routerLink]="item.command()"
          [queryParams]="item.args()?.queryParams"
          [fragment]="item.args()?.fragment"
          [queryParamsHandling]="item.args()?.queryParamsHandling"
          [state]="item.args()?.state"
          [info]="item.args()?.info"
          [relativeTo]="item.args()?.relativeTo"
          [preserveFragment]="item.args()?.preserveFragment"
          [skipLocationChange]="item.args()?.skipLocationChange"
          [replaceUrl]="item.args()?.replaceUrl"
          [target]="item.target() || '_self'"
          [disabled]="item.disabled()"
          routerLinkActive
        ></a>
      }
    }
  }
}
