import { Routes } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductComponent } from './product/product.component';
import { DefaultComponent, DescriptionComponent, LinksComponent, SpecificationsComponent } from './product/tabs';
import { AlternatesComponent } from './product/tabs/alternates';
import { PartnosComponent } from './product/tabs/partnos/partnos.component';
import { SupplementalComponent } from './product/tabs/supplemental';
import { ProductCategoriesResolver } from './resolvers/product-categories.resolver';
import { ProductListResolver } from './resolvers/product-list.resolver';
import { ProductResolver } from './resolvers/product.resolver';
import { VendorListResolver } from './resolvers/vendor-list-resolver';

export const productRoutes: Routes = [
  {
    path: '',
    component: ProductListComponent,
    data: {
      legacyLoadIndicator: true,
    },
    resolve: {
      products: ProductListResolver,
      vendors: VendorListResolver,
      categories: ProductCategoriesResolver,
    },
  },
  {
    path: ':productId',
    component: ProductComponent,
    data: {
      legacyLoadIndicator: true,
    },
    resolve: {
      product: ProductResolver,
    },
    children: [
      {
        path: '',
        component: DefaultComponent,
      },
      {
        path: 'links',
        component: LinksComponent,
      },
      {
        path: 'specs',
        component: SpecificationsComponent,
      },
      {
        path: 'description',
        component: DescriptionComponent,
      },
      {
        path: 'partnumbers',
        component: PartnosComponent,
      },
      {
        path: 'supplemental',
        component: SupplementalComponent,
      },
      {
        path: 'alternates',
        component: AlternatesComponent,
      },
    ],
  },
];
