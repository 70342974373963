import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { AppPrimaryMenuRenderTargetComponent } from './app-primary-menu-render-target.component';

@Directive({
  selector: '[renderInAppPrimaryMenu]',
  standalone: true,
})
export class RenderInAppPrimaryMenuDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(AppPrimaryMenuRenderTargetComponent);
}
