import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ApplicationService } from 'app-shell-data';

@Component({
  selector: 'ideal-app-header-handle',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './app-header-handle.component.html',
  styleUrl: './app-header-handle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': '!headerHidden()',
    '[class.disabled]': 'scrollAtTop()',
    '(click)': 'toggleHeader()',
  },
})
export class AppHeaderHandleComponent {
  private readonly _store = inject(ApplicationService);
  protected readonly headerHidden = this._store.header.hideOnScrollEnabled;
  protected readonly scrollAtTop = this._store.header.scrollIsAtTop;

  protected toggleHeader() {
    this._store.header.toggleHeader();
  }
}
