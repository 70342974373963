import { Injector, computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { addEntities, removeEntities, withEntities } from '@ngrx/signals/entities';
import { NAV_ITEM } from '../injection-tokens';
import { NavItemDataTypes, NavItemTypes } from '../types';
import { NavItemData } from '../types/base/NavItem';
import { addItem } from './util/addItem';

export const NavigationStore = signalStore(
  { providedIn: 'root' },
  withEntities<NavItemData>(),
  withMethods((store) => {
    const injector = inject(Injector);
    return {
      add: (...items: NavItemTypes[]) => patchState(store, (state) => addEntities(items.map((item) => addItem(injector, item)))(state)),
      remove: (...ids: string[]) => patchState(store, removeEntities(ids)),
    };
  }),
  withComputed(({ entities }) => {
    const sortedEntities = computed(() =>
      entities().sort((a, b) => {
        const aPriority = a.priority();
        const bPriority = b.priority();
        if (aPriority < bPriority) return 1;
        if (aPriority > bPriority) return -1;
        return a.label().localeCompare(b.label());
      }),
    );

    const visibleEntities = computed(() => sortedEntities().filter((item) => !item.hidden()));

    return {
      sortedEntities,
      visibleEntities,
    };
  }),
  withMethods((store) => {
    const getById = (id: string) => store.entities().find((item) => item.id === id);
    const hasId = (id: string) => store.ids().includes(id);
    const getDescendantsOf = (id: string, includeHidden = false) => {
      const items = includeHidden ? store.entities() : store.visibleEntities();
      return items.filter((item) => item.id.startsWith(id) && item.id !== id) as NavItemDataTypes[];
    };

    const getChildrenOf = (id: string, includeHidden = false) => {
      const numChildPathParts = id.split('/').length + 1;
      const items = getDescendantsOf(id, includeHidden);
      return items.filter((item) => item.id.split('/').length === numChildPathParts) as NavItemDataTypes[];
    };

    return {
      getById,
      hasId,
      getDescendantsOf,
      getChildrenOf,
    };
  }),
  withHooks((store) => {
    const injectedNavItems = inject(NAV_ITEM, { optional: true }) ?? [];
    return {
      onInit: () => {
        store.add(...injectedNavItems);
      },
    };
  }),
);
