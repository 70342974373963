<div matRipple [matRippleColor]="''" [matRippleCentered]="false"></div>
<div content>
  <!-- @if (icon()) {
    <mat-icon class="icon material-symbols-outlined">{{ icon() }}</mat-icon>
  } -->
  <label>{{ label() }}</label>
  @if (menu()) {
    <mat-icon class="arrow">arrow_right</mat-icon>
  }
</div>
