import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { CrossReferenceApi, Product } from '@idealsupply/ngclient-webservice-inventory';
import { CustomerReference } from '@idealsupply/ngclient-webservice-shopping-cart';
import { CustomerService } from 'lib-customer';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatAnchor } from '@angular/material/button';
import { MatCard, MatCardTitle, MatCardContent } from '@angular/material/card';
import { TabsComponent } from 'lib-common-components';
import { CartItemFeeCalcComponent, CartAddRemoveButtonComponent } from 'lib-cart';
import {
  ProductImageViewerComponent,
  ProductListItemDetailComponent,
  ProductListItemPriceComponent,
  ProductListItemStockComponent,
} from 'lib-products';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'feature-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    ProductImageViewerComponent,
    ProductListItemDetailComponent,
    ProductListItemPriceComponent,
    CartItemFeeCalcComponent,
    TabsComponent,
    RouterOutlet,
    MatCard,
    CartAddRemoveButtonComponent,
    ProductListItemStockComponent,
    MatAnchor,
    MatCardTitle,
    MatCardContent,
    NgFor,
    MatFormField,
    MatLabel,
    MatInput,
    AsyncPipe,
  ],
})
export class ProductComponent {
  public product$ = this.route.data.pipe(map((data) => data['product'] as Product));

  public tabs$ = this.product$.pipe(
    map((product) => {
      const tabs: { label: string; cmd: string[] }[] = [];
      if (product.longDescriptions?.length) {
        tabs.push({
          label: 'Description',
          cmd: ['./description'],
        });
      }

      if (product.specs?.length) {
        tabs.push({
          label: 'Specifications',
          cmd: ['./specs'],
        });
      }
      if (product.links?.length) {
        tabs.push({
          label: 'Links',
          cmd: ['./links'],
        });
      }
      if (product.longPartNumbers?.length) {
        tabs.push({
          label: 'Long Part Numbers',
          cmd: ['./partnumbers'],
        });
      }

      if (product.supplementalProducts?.length) {
        tabs.push({
          label: 'Supplemental Products',
          cmd: ['./supplemental'],
        });
      }

      if (product.alternateProducts?.length) {
        tabs.push({
          label: 'Alternate Products',
          cmd: ['./alternates'],
        });
      }

      return tabs;
    }),
  );

  public references$ = combineLatest([this.customerService.availableCustomerNumbersChange, this.product$]).pipe(
    map(([customerNumbers, product]) =>
      customerNumbers.map(
        (item) =>
          ({
            customerNumber: item.customerNumber,
            crossReference: product.crossReferences?.find((i) => i.customerNumber === item.customerNumber)?.crossReference,
          }) as CustomerReference,
      ),
    ),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly customerService: CustomerService,
    private readonly crossReferenceApi: CrossReferenceApi,
  ) {}

  public async setCrossReference(product: Product, customerNumber: string, value: string) {
    if (value) {
      await this.crossReferenceApi
        .setCrossReference(product.lineCode + product.partNumber, customerNumber, JSON.stringify(value))
        .toPromise();
    } else {
      await this.crossReferenceApi.unsetCrossReference(product.lineCode + product.partNumber, customerNumber).toPromise();
    }
  }
}
