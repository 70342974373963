import { DOCUMENT, isPlatformBrowser, NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, PLATFORM_ID, signal } from '@angular/core';
import { AppDimension, AppDimensionKeys, AppOrientation } from 'common-ui';
import { fadeOutSplashScreenAnimation } from './animations/fadeOutSplashScreenAnimation';
import { AppBodyComponent } from './components/app-body/app-body.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { LegacyLoadingScreenComponent } from './components/legacy-loading-screen/legacy-loading-screen.component';
import { AppSearchFieldComponent } from './components/search/app-search-field/app-search-field.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { RenderInAppToolbarDirective } from './render-targets';
import { AppHeaderDrawersComponent } from './render-targets/app-header-drawers/app-header-drawers.component';
import { AppSideDrawersComponent } from './render-targets/app-side-drawers/app-side-drawers.component';
import { ApplicationService } from 'app-shell-data';
import { ElementResizeDirective } from 'common-util';

@Component({
  selector: 'ideal-app-shell',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppHeaderDrawersComponent,
    AppMenuComponent,
    AppSideDrawersComponent,
    AppBodyComponent,
    NgComponentOutlet,
    RenderInAppToolbarDirective,
    AppSearchFieldComponent,
    LegacyLoadingScreenComponent,
    SplashScreenComponent,
    ElementResizeDirective,
  ],
  templateUrl: './app-shell.component.html',
  styleUrl: './app-shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeOutSplashScreenAnimation],
  host: {
    '[@fadeOutSplashScreen]': '',
    '(@fadeOutSplashScreen.done)': 'onSplashAnimDone()',
    '[class.splash-screen]': 'showSplashScreen()',
    '[style.--ideal-app-shell--header--height]': 'headerHeight() + "px"',
  },
})
export class AppShellComponent {
  private readonly _store = inject(ApplicationService);
  private readonly _document = inject(DOCUMENT);
  private readonly _isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  protected readonly featureImportComponents = this._store.featureImportComponents.components;
  protected readonly hasSearchbar = this._store.searchbar.hasSearchbar;
  protected readonly showPopoverBackdrop = this._store.isPopoversActive;
  protected readonly showSplashScreen = signal(true);
  protected readonly headerHeight = signal(0);

  protected onPopoverBackdropClick() {
    this._store.closePopovers();
  }

  protected onSplashAnimDone() {
    if (this._isBrowser) {
      this.showSplashScreen.set(false);
    }
  }

  constructor() {
    this.updateSizeAttrs();
  }

  private updateSizeAttrs() {
    if (!this._isBrowser) return;

    effect(() => {
      const w = this._store.breakpointWidth();
      const h = this._store.breakpointHeight();
      const o = this._store.breakpointOrientation();

      const elm = this._document.documentElement;
      const dims = AppDimensionKeys;
      const dim = AppDimension;

      elm.setAttribute('h-eq', dim[w]);
      elm.setAttribute('h-lt', dims.filter((k) => w < dim[k]).join(' '));
      elm.setAttribute('h-lte', dims.filter((k) => w <= dim[k]).join(' '));
      elm.setAttribute('h-gte', dims.filter((k) => w >= dim[k]).join(' '));
      elm.setAttribute('h-gt', dims.filter((k) => w > dim[k]).join(' '));

      elm.setAttribute('v-eq', dim[h]);
      elm.setAttribute('v-lt', dims.filter((k) => h < dim[k]).join(' '));
      elm.setAttribute('v-lte', dims.filter((k) => h <= dim[k]).join(' '));
      elm.setAttribute('v-gte', dims.filter((k) => h >= dim[k]).join(' '));
      elm.setAttribute('v-gt', dims.filter((k) => h > dim[k]).join(' '));

      elm.setAttribute('orientation', AppOrientation[o].toLowerCase());
    });
  }
}
