import { Directive, input, signal } from '@angular/core';
import { NamedRenderInSource, RenderInSourceDirectiveBase } from 'common-ui';
import { AppSideDrawersComponent } from './app-side-drawers.component';

@Directive({
  selector: '[appSideDrawer]',
  standalone: true,
})
export class AppSideDrawerDirective extends RenderInSourceDirectiveBase implements NamedRenderInSource {
  protected readonly renderIn = signal(AppSideDrawersComponent);

  public readonly drawer = input.required<string>({ alias: 'appSideDrawer' });
  public get renderInSourceName() {
    return this.drawer();
  }
}
