import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { AppToolbarRenderTargetComponent } from './app-toolbar-render-target.component';

@Directive({
  selector: '[renderInAppToolbar]',
  standalone: true,
})
export class RenderInAppToolbarDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(AppToolbarRenderTargetComponent);
}
