import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Size, breakpointValue, ifThen, otherwise } from '@cpangular/rxjs';
import { AppDimension, AppOrientation, HorizontalBreakpointsMin, VerticalBreakpointsMin, isPortrait } from 'common-ui';
import { Observable, combineLatest, distinctUntilChanged, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppBreakpointService {
  public static readonly AppOrientations = AppOrientation;
  public readonly AppOrientations = AppOrientation;

  private readonly _appDimensionHorizontal$ = breakpointValue(
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.xs], AppDimension.xxs),
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.sm], AppDimension.xs),
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.md], AppDimension.sm),
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.lg], AppDimension.md),
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.xl], AppDimension.lg),
    ifThen((screen: Size) => screen.width < HorizontalBreakpointsMin[AppDimension.xxl], AppDimension.xl),
    otherwise(AppDimension.xxl),
  ) as Observable<AppDimension>;

  private readonly _appDimensionVertical$ = breakpointValue(
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.xs], AppDimension.xxs),
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.sm], AppDimension.xs),
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.md], AppDimension.sm),
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.lg], AppDimension.md),
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.xl], AppDimension.lg),
    ifThen((screen: Size) => screen.height < VerticalBreakpointsMin[AppDimension.xxl], AppDimension.xl),
    otherwise(AppDimension.xxl),
  ) as Observable<AppDimension>;

  public readonly appDimensions$ = combineLatest([this._appDimensionHorizontal$, this._appDimensionVertical$]).pipe(
    distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
    map(([width, height]) => ({ width, height })),
    shareReplay(1),
  );

  private readonly _appOrientation$ = breakpointValue(
    ifThen((screen: Size) => isPortrait(screen), AppOrientation.Portrait),
    otherwise(AppOrientation.Landscape),
  ) as Observable<AppOrientation>;

  public readonly appOrientation$ = this._appOrientation$.pipe(shareReplay(1));
  public readonly appOrientation = toSignal(this.appOrientation$, { initialValue: AppOrientation.Landscape });
  public readonly appDimensions = toSignal(this.appDimensions$, { initialValue: { width: AppDimension.xl, height: AppDimension.xl } });
}
