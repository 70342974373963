import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { ProductListItemArrayDataSource } from 'lib-products';

import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { ProductPurchaseListComponent } from 'lib-cart';
import { MatCard } from '@angular/material/card';

@Component({
  selector: 'feature-supplemental',
  templateUrl: './supplemental.component.html',
  styleUrls: ['./supplemental.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCard, ProductPurchaseListComponent, AsyncPipe],
})
export class SupplementalComponent {
  public product$ = this.route.parent!.data.pipe(map((data) => data['product'] as Product));

  public supplementalItems$ = this.product$.pipe(
    map((p) => (p.supplementalProducts ? new ProductListItemArrayDataSource(p.supplementalProducts) : null)),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  public handleTitleClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.router.navigate(['/products', event.detail.item.lineCode + event.detail.item.partNumber]);
  }
}
