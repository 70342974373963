<div>
  <mat-form-field>
    <input
      matInput
      #input
      type="text"
      (focus)="openWindow()"
      (blur)="onBlurStart($event)"
      [value]="searchQuery()"
      (input)="valueUpdated(input.value)"
      [placeholder]="placeholderText()"
      size="1"
      (keypress)="onInputKeyPress($event)"
    />
    @if (resultsOpen() && hasSearchResults()) {
      <button #focusStartButton focus="start" (focus)="onFocusStart($event)"></button>
    }
    <ideal-search-clear-button
      #clearButton
      matPrefix
      [reset]="!!searchQuery()"
      (click)="searchQuery() ? valueUpdated('') : input.focus()"
      [disabled]="!searchQuery()"
    ></ideal-search-clear-button>

    @if (resultsOpen()) {
      <ideal-window-toggle-button
        #toggleButton
        matSuffix
        open
        (click)="closeWindow(); $event.stopPropagation()"
        (blur)="onBlurEnd($event)"
      ></ideal-window-toggle-button>
    }

    @if (resultsOpen() && hasSearchResults()) {
      <button #focusEndButton focus="end" (focus)="onFocusEnd($event)"></button>
    }
  </mat-form-field>
</div>

<ideal-app-search-window
  *appHeaderDrawer="'search-results'"
  [searchBarPosition]="position()"
  [searchBarExpanded]="expandBar()"
  (focusStart)="onWindowFocusStart($event)"
  (focusEnd)="onWindowFocusEnd($event)"
>
</ideal-app-search-window>
