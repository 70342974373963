import { computed, inject } from '@angular/core';
import { signalStoreFeature, withComputed } from '@ngrx/signals';
import { AppBreakpointService } from '../../services';

export function withAppBreakpoints() {
  return signalStoreFeature(
    withComputed(() => {
      const bp = inject(AppBreakpointService);
      return {
        breakpointWidth: computed(() => bp.appDimensions().width),
        breakpointHeight: computed(() => bp.appDimensions().height),
        breakpointOrientation: computed(() => bp.appOrientation()),
      };
    }),
  );
}
