import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, computed, effect, inject, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { ElementResizeDirective } from 'common-util';
import { Subscription, filter, first, fromEvent } from 'rxjs';
import { AppHeaderDrawerDirective } from '../../../render-targets/app-header-drawers';
import { AppSearchWindowComponent } from '../app-search-window/app-search-window.component';
import { AppSearchComponentStore } from '../state/app-search.component.store';
import { SearchClearButtonComponent } from './components/search-clear-button/search-clear-button.component';
import { WindowToggleButtonComponent } from './components/window-toggle-button/window-toggle-button.component';

@Component({
  selector: 'ideal-app-search-field',
  standalone: true,
  imports: [
    SearchClearButtonComponent,
    AppSearchWindowComponent,
    WindowToggleButtonComponent,
    CdkTrapFocus,
    AppHeaderDrawerDirective,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './app-search-field.component.html',
  styleUrl: './app-search-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ElementResizeDirective],
  host: {
    'theme-density': '0',
    '[class.expanded]': 'expandBar()',
  },
  providers: [AppSearchComponentStore],
})
export class AppSearchFieldComponent implements OnDestroy {
  private readonly _appSearchStore = inject(AppSearchComponentStore);
  private readonly _elmRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly _router = inject(Router);
  private readonly _resizeDirective = inject(ElementResizeDirective, { self: true });
  private readonly _clearButtonRef = viewChild<SearchClearButtonComponent>(SearchClearButtonComponent);
  private readonly _inputRef = viewChild<string, ElementRef<HTMLInputElement>>('input', { read: ElementRef });
  private readonly _toggleButtonRef = viewChild<WindowToggleButtonComponent>(WindowToggleButtonComponent);

  protected readonly placeholderText = this._appSearchStore.placeholderText;
  protected readonly resultsOpen = this._appSearchStore.resultsOpen;
  protected readonly size = toSignal(this._resizeDirective.sizeChange, { initialValue: { width: 1, height: 1 } });
  protected readonly width = computed(() => this.size().width);
  protected readonly height = computed(() => this.size().height);
  protected readonly position = computed(() => {
    try {
      const rect = this._elmRef.nativeElement.getBoundingClientRect();
      const pos = {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        width: this.width(),
        height: this.height(),
      };
      return pos;
    } catch {
      return { left: 0, top: 0, width: 0, height: 0 };
    }
  });

  private readonly expandBarOnOpen = computed(() => this.width() < 600);

  private _clickEventSub?: Subscription;
  private readonly _searchWindowRef = viewChild<AppSearchWindowComponent, ElementRef<HTMLElement>>(AppSearchWindowComponent, {
    read: ElementRef,
  });

  protected readonly searchQuery = this._appSearchStore.searchQuery;
  protected readonly searchResultCount = this._appSearchStore.searchResultCount;
  protected readonly hasSearchResults = this._appSearchStore.hasSearchResults;
  protected readonly expandBar = computed(() => {
    const expand = this.expandBarOnOpen();
    const open = this.resultsOpen();
    return expand && open;
  });

  protected valueUpdated(value: string) {
    this._appSearchStore.search(value);
  }

  protected openWindow() {
    if (!this.resultsOpen()) {
      const obs = fromEvent<MouseEvent>(document, 'mousedown').pipe(
        filter(
          (event) =>
            !this._elmRef.nativeElement.contains(event.target as HTMLElement) &&
            !this._searchWindowRef()?.nativeElement.contains(event.target as HTMLElement),
        ),
        first(),
      );
      this._clickEventSub = obs.subscribe(() => {
        this._appSearchStore.closeResults();
      });
      this._appSearchStore.openResults();
    }
  }

  protected closeWindow() {
    this._clickEventSub?.unsubscribe();
    this._appSearchStore.closeResults();
  }

  protected onInputKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.searchResultCount() > 0) {
        this._searchWindowRef()?.nativeElement.querySelector<HTMLAnchorElement | HTMLButtonElement>('a, button:not([focus])')?.click();
      }
    }
  }

  protected onFocusStart(event: FocusEvent) {
    const buttons = this._searchWindowRef()?.nativeElement.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>(
      'a, button:not([focus])',
    );
    if (buttons?.length) {
      buttons.item(buttons.length - 1).focus();
    }
  }

  protected onFocusEnd(event: FocusEvent) {
    this._searchWindowRef()?.nativeElement.querySelector<HTMLAnchorElement | HTMLButtonElement>('a, button:not([focus])')?.focus();
  }

  protected onBlurStart(event: FocusEvent) {
    if (this.searchResultCount() === 0) {
      setTimeout(() => {
        if (!this._elmRef.nativeElement.querySelector('*:focus')) {
          this.closeWindow();
        }
      });
    }
  }
  protected onBlurEnd(event: FocusEvent) {
    if (this.searchResultCount() === 0) {
      this.closeWindow();
    }
  }

  protected onWindowFocusStart(event: FocusEvent) {
    this._toggleButtonRef()?.focus();
  }

  protected onWindowFocusEnd(event: FocusEvent) {
    const clearButton = this._clearButtonRef();
    if (clearButton && !clearButton.disabled()) {
      clearButton.focus();
    } else {
      this._inputRef()?.nativeElement.focus();
    }
  }

  constructor() {
    effect(() => {
      this._elmRef.nativeElement.style.setProperty('--ideal-app-search-field--width', `${this.width()}px`);
      this._elmRef.nativeElement.style.setProperty('--ideal-app-search-field--height', `${this.height()}px`);
    });
  }

  ngOnDestroy(): void {
    this._clickEventSub?.unsubscribe();
  }
}
