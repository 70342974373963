import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { AppActionsMainRenderTargetComponent } from './app-actions-main-render-target.component';

@Directive({
  selector: '[renderInAppActionsMain]',
  standalone: true,
})
export class RenderInAppActionsMainDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(AppActionsMainRenderTargetComponent);
}
