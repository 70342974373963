import { ButtonNavItem, ButtonNavItemData } from './ButtonNavItem';
import { evalItemProp } from '../util/evalItemProp';
import { convertNavItem } from '../base/convertNavItem';

export function convertButtonNavItem(item: ButtonNavItem): ButtonNavItemData {
  return {
    ...convertNavItem(item),
    type: item.type,
    onClick: item.onClick(),
    active: evalItemProp(item.active, false),
  };
}
