import { computed, inject } from '@angular/core';
import { signalStore, withComputed } from '@ngrx/signals';
import { NavigationStore } from 'navigation-data';

export const PrimaryNavigationBarComponentStore = signalStore(
  withComputed(() => {
    const navStore = inject(NavigationStore);

    const grouped = computed(() => {
      const navItems = navStore.getChildrenOf('app.main-menu');
      return Object.groupBy(navItems, (i) => {
        const heading = i.heading()?.toLowerCase() ?? '';
        switch (heading) {
          case '':
          case 'top':
            return 'top';
          case 'bottom':
            return 'bottom';
          default:
            return 'more';
        }
      });
    });

    const topItems = computed(() => grouped().top ?? []);
    const hasTopItems = computed(() => topItems().length > 0);
    const bottomItems = computed(() => grouped().bottom ?? []);
    const hasBottomItems = computed(() => bottomItems().length > 0);
    const moreItems = computed(() => grouped().more ?? []);
    const hasMoreItems = computed(() => moreItems().length > 0);

    return {
      topItems,
      hasTopItems,
      bottomItems,
      hasBottomItems,
      moreItems,
      hasMoreItems,
    };
  }),
);
