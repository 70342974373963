import { Signal } from '@angular/core';
import { NavItemDataProperty } from '../NavItemDataProperty';
import { NavItemType } from '../NavItemType';

const navItemTypeValues = Object.values(NavItemType) as NavItemType[];

export interface NavItemData {
  id: string;
  type: NavItemType;
  label: Signal<string>;
  icon: Signal<string | undefined>;
  heading: Signal<string>;
  priority: Signal<number>;
  hidden: Signal<boolean>;
  disabled: Signal<boolean>;
}

export interface NavItem {
  id: string;
  type: NavItemType;
  label: NavItemDataProperty<string>;
  icon?: NavItemDataProperty<string>;
  heading?: NavItemDataProperty<string>;
  priority?: NavItemDataProperty<number>;
  hidden?: NavItemDataProperty<boolean>;
  disabled?: NavItemDataProperty<boolean>;
}

export function isNavItem(item: NavItem): item is NavItem {
  return typeof item === 'object' && 'id' in item && 'type' in item && navItemTypeValues.includes(item.type);
}
