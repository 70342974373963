import { InjectionToken, Signal } from '@angular/core';
import { SearchServiceState } from './SearchServiceState';

export interface SearchService<TResult = unknown> {
  readonly searchState: Signal<SearchServiceState>;
  readonly searchError: Signal<any>;
  readonly searchQuery: Signal<string>;
  readonly searchResults: Signal<TResult>;
  readonly searchResultCount: Signal<number>;
  search(value: string): void;
}

export const SearchService = new InjectionToken<SearchService>('SearchService');
