<ideal-drawer position="top" [open]="!headerHidden()">
  <header>
    <div class="bg primary"></div>
    <div class="bg secondary"></div>
    <div class="wedge">
      <svg version="1.1" viewBox="0 0 70 100">
        <path d="M 0,0 H 5 L 65,100 H 0 Z" />
      </svg>
    </div>
    <ideal-app-header-menu-button [open]="menuIsOpen()" (click)="toggleMenu()" />
    <ideal-app-header-logo />
    <ideal-render-target-app-toolbar />
    <ideal-render-target-app-actions-main />
    <ideal-render-target-app-actions-secondary />
  </header>
</ideal-drawer>
@if (hideHeaderOnScrollEnabled()) {
  <ideal-app-header-handle />
  <!--
  <div handle-container>
     <div handle [class.open]="!headerHidden()" (click)="toggleHeaderHidden()">
      <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
    </div>
  </div>
  -->
}
