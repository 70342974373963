import { convertRouteNavItem } from '../route/convertRouteNavItem';
import { evalItemProp } from '../util/evalItemProp';
import { RouteButtonNavItem, RouteButtonNavItemData } from './RouteButtonNavItem';

export function convertRouteButtonNavItem(item: RouteButtonNavItem): RouteButtonNavItemData {
  return {
    ...convertRouteNavItem(item),
    type: item.type,
    command: evalItemProp(item.command),
    args: evalItemProp(item.args),
  };
}
