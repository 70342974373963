import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '@idealsupply/ngclient-webservice-inventory';
import { ProductAttributeListComponent } from 'lib-products';

@Component({
  selector: 'feature-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProductAttributeListComponent],
})
export class LinksComponent {
  public product?: Product;

  constructor(private readonly route: ActivatedRoute) {
    route.parent?.data.subscribe((data) => {
      this.product = data['product'];
    });
  }
}
