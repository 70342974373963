import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DrawerComponent } from 'common-ui';
import { NavigationListComponent } from 'navigation-ui';
import { SecondaryNavigationFrameRenderTargetComponent } from './renter-targets/secondary-navigation-frame-render-target.component';
import { SecondaryNavigationRenderTargetComponent } from './renter-targets/secondary-navigation-render-target.component';
import { SecondaryNavigationMenuComponentStore } from './secondary-navigation-menu.store';

@Component({
  selector: 'ideal-secondary-navigation-menu',
  standalone: true,
  imports: [
    DrawerComponent,
    SecondaryNavigationRenderTargetComponent,
    SecondaryNavigationFrameRenderTargetComponent,
    NavigationListComponent,
  ],
  templateUrl: './secondary-navigation-menu.component.html',
  styleUrl: './secondary-navigation-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SecondaryNavigationMenuComponentStore],
})
export class SecondaryNavigationMenuComponent {
  protected navStore = inject(SecondaryNavigationMenuComponentStore);
}
