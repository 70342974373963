import { Directive, signal } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { AppQuickSearchResultsRenderTargetComponent } from './app-quick-search-results-render-target.component';

@Directive({
  selector: '[renderInAppQuickSearchResults]',
  standalone: true,
})
export class RenderInAppQuickSearchResultsDirective extends RenderInSourceDirectiveBase {
  protected readonly renderIn = signal(AppQuickSearchResultsRenderTargetComponent);
}
