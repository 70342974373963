import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  ButtonNavItemData,
  ExternalLinkNavItemData,
  NavItemDataTypes,
  NavItemType,
  RouteButtonNavItemData,
  RouteLinkNavItemData,
} from 'navigation-data';
import { NavigationMenuBarButtonComponent } from '../navigation-menu-bar-button/navigation-menu-bar-button.component';

enum Orientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
type OrientationValues = `${Orientation}`;

@Component({
  selector: 'ideal-navigation-menu-bar',
  standalone: true,
  imports: [NavigationMenuBarButtonComponent, RouterLink, RouterLinkActive, CdkScrollable],
  templateUrl: './navigation-menu-bar.component.html',
  styleUrls: ['../navigation-theme-vars.scss', './navigation-menu-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.orientation]': 'orientation()',
  },
  hostDirectives: [CdkScrollable],
})
export class NavigationMenuBarComponent {
  protected readonly NavItemType = NavItemType;
  protected readonly Orientation = Orientation;

  public readonly items = input.required<NavItemDataTypes[]>();
  public readonly orientation = input<Orientation, Orientation | OrientationValues>(Orientation.Horizontal, {
    transform: (value) => (value?.toLowerCase() as Orientation) || Orientation.Horizontal,
  });

  protected cast(item: NavItemDataTypes, type: NavItemType.Button): ButtonNavItemData;
  protected cast(item: NavItemDataTypes, type: NavItemType.ExternalLink): ExternalLinkNavItemData;
  protected cast(item: NavItemDataTypes, type: NavItemType.RouteButton): RouteButtonNavItemData;
  protected cast(item: NavItemDataTypes, type: NavItemType.RouteLink): RouteLinkNavItemData;
  protected cast(item: NavItemDataTypes, _: NavItemType): any {
    return item;
  }
}
