import { Signal, isSignal, signal } from '@angular/core';
import { NavItemDataProperty } from '../NavItemDataProperty';

export function evalItemProp<T>(prop: NavItemDataProperty<T>): Signal<T>;
export function evalItemProp<T>(prop: NavItemDataProperty<T> | undefined, defaultVal: T): Signal<T>;
export function evalItemProp<T>(prop: NavItemDataProperty<T> | undefined, defaultVal?: T | undefined): Signal<T> | Signal<undefined>;
export function evalItemProp<T>(prop: NavItemDataProperty<T> | undefined, defaultVal?: T | undefined): Signal<T> | Signal<undefined> {
  if (!isSignal(prop) && typeof prop === 'function') {
    return (prop as () => Signal<T>)();
  }
  return prop ?? (signal(defaultVal) as Signal<T> | Signal<undefined>);
}
