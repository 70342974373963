import { InjectionToken, Provider, ValueProvider } from '@angular/core';
import { NavItemTypes, SignalOrValueProps } from './types/NavItemTypes';

export const NAV_ITEM = new InjectionToken<NavItemTypes[]>('Navigation Item');

export function provideNavItems(...navItems: SignalOrValueProps<NavItemTypes>[]): Provider {
  const providers = navItems.map(
    (navItem) =>
      ({
        provide: NAV_ITEM,
        useValue: navItem,
        multi: true,
      }) as ValueProvider,
  );
  return providers;
}
