import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withPersistence } from '../features/withPersistence';

export interface AppPopoverState {
  activeId: string | null;
}

export const AppPopoverStore = signalStore(
  { providedIn: 'root' },
  withState<AppPopoverState>(() => ({
    activeId: null,
  })),
  withComputed((state) => ({
    isActive: computed(() => state.activeId() !== null),
  })),
  withMethods((state) => ({
    setActive: (activeId: string | null) => patchState(state, { activeId }),
  })),
  withPersistence({
    key: 'idealsupply.ideallink.app-shell.popovers',
    persist: (store) => ({
      activeId: store.activeId(),
    }),
    restore: (store, data) => {
      store.setActive(data.activeId);
    },
  }),
);
