import { NavItem, NavItemData } from './NavItem';
import { evalItemProp } from '../util/evalItemProp';

export function convertNavItem(item: NavItem): NavItemData {
  return {
    id: item.id,
    type: item.type,
    label: evalItemProp(item.label),
    icon: evalItemProp(item.icon),
    heading: evalItemProp(item.heading, ''),
    priority: evalItemProp(item.priority, 0),
    hidden: evalItemProp(item.hidden, false),
    disabled: evalItemProp(item.disabled, false),
  };
}
